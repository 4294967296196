import React from 'react';
import {

  SimpleShowLayout,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import { Chip } from '@mui/material';

import CurrencyField from '../utils/CurrencyField';
import FieldsGroup from '../utils/FieldsGroup';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

import TitleH1 from '../designSystem/TitleH1';
import Show from '../designSystem/react-admin/Show';
import { colors } from '../designSystem/ListChip';
import DateField from '../designSystem/react-admin/DateField';
import { EntityReferenceField } from '../designSystem/react-admin/ReferenceField';

import GroupedCreditTransactions from './GroupedCreditTransactions';

import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

import { useConstantContext } from '../ConstantsContext';

export const getGroupedCreditShowTitle = (record) => (record && record.groupedCreditReference ? record.groupedCreditReference : '');

const Title = () => {
  const { t } = useTranslation();
  const record = useRecordContext();
  if (!record) return null;

  let statusChip = (
    <Chip
      style={{
        backgroundColor: colors.red.background,
        color: colors.red.text,
      }}
      label={t('Unplaced')}
    />
  );
  if (record.accountStatus === 'closed') {
    statusChip = (
      <Chip
        style={{
          backgroundColor: colors.gray.background,
          color: colors.gray.text,
        }}
        label={t('Placed')}
      />
    );
  }

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <TitleH1 title={`${t('Grouped credit')}: ${getGroupedCreditShowTitle(record)}`} sx={{ marginRight: '1rem' }} />
      {statusChip}
    </div>
  );
};

export const FirstTransactionAmount = () => {
  const record = useRecordContext();
  const { t } = useTranslation();

  if (!record?.transactions?.length) return null;

  const firstTransaction = record.transactions[0];

  return (
    <CurrencyField
      source="amount"
      label={t('Initial amount')}
      customRecord={{
        amount: firstTransaction.amount,
      }}
      coefficient={-1}
      currency={record.currency}
    />
  );
};

const GroupedCreditShow = () => {
  const { t } = useTranslation();
  const { isFinancer } = useUserPermissionsAndRoles();
  const { constants } = useConstantContext();
  const { userActions } = constants;

  const canViewCustomer = useHasUserPermissions(
    constants,
    userActions.VIEW_CUSTOMERS,
  );
  if (!canViewCustomer) return null;

  return (
    <Show
      title={<Title />}
      appLocation="home.grouped-credit.show"
    >
      <SimpleShowLayout>
        <FieldsGroup>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            width="100%"
            gap="2.5rem"
            alignSelf="flex-start"
          >
            {isFinancer && (
              <SimpleShowLabeled>
                <EntityReferenceField
                  label={t('Merchant')}
                  source="merchantId"
                  reference="entity-v2/merchant"
                  link={({ entityDetailsId }) => `/entity-v2/merchant/${entityDetailsId}`}
                  isPage
                />
              </SimpleShowLabeled>
            )}
            <SimpleShowLabeled>
              <EntityReferenceField
                label={t('Customer')}
                source="debtorId"
                reference="entity-v2/customer"
                link={({ entityDetailsId }) => `/entity-v2/customer/${entityDetailsId}`}
                isPage
              />
            </SimpleShowLabeled>
            <SimpleShowLabeled>
              <DateField
                source="createdAt"
                label={t('Creation date')}
              />
            </SimpleShowLabeled>
            <SimpleShowLabeled>
              <FirstTransactionAmount label={t('Initial amount')} />
            </SimpleShowLabeled>
            <SimpleShowLabeled>
              <CurrencyField source="balance" currencySource="currency" label={t('Balance')} />
            </SimpleShowLabeled>
          </Box>
          <GroupedCreditTransactions />
        </FieldsGroup>
      </SimpleShowLayout>
    </Show>
  );
};

export default GroupedCreditShow;
