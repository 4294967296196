import React from 'react';

import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';

import { TextField } from 'react-admin';

import FieldsGroup from '../../utils/FieldsGroup';
import SimpleShowLabeled from '../../utils/SimpleShowLabeled';

import UpdateBankAccountsButton from './UpdateBankAccountsButton';
import { useConstantContext } from '../../ConstantsContext';

import useHasUserPermissions from '../../hooks/useHasUserPermissions';

const BankAccountsGroup = () => {
  const { t } = useTranslation();
  const { constants } = useConstantContext();
  const { userActions } = constants;

  const canView = useHasUserPermissions(
    constants,
    userActions.VIEW_MERCHANTS,
  );

  if (!canView) return null;

  return (
    <FieldsGroup
      title={t('Assigned bank accounts')}
      actions={<UpdateBankAccountsButton />}
    >
      <Grid container>
        <Grid item lg={4} md={6}>
          <Box style={{ position: 'relative' }}>
            <Tooltip title={t('Enter the Bankgiro number to allow the system to verify that incoming payments are correctly matched to the corresponding merchant')}>
              <InfoIcon
                color="secondary"
                fontSize="extraSmall"
                sx={{
                  position: 'absolute',
                  top: '10%',
                  marginLeft: '3.5rem',
                }}
              />
            </Tooltip>
            <SimpleShowLabeled>
              <TextField source="bankgiro" label={t('Bankgiro')} emptyText="-" />
            </SimpleShowLabeled>
          </Box>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="bic" label={t('BIC')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="iban" label={t('IBAN')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
      </Grid>
    </FieldsGroup>
  );
};

export default BankAccountsGroup;
