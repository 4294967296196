import React from 'react';

import {
  TextField,
  required,
  NumberField,
  useRecordContext,
  useResourceContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import SimpleShowLabeled from '../../utils/SimpleShowLabeled';

import TitleH5 from '../../designSystem/TitleH5';
import BooleanField from '../../designSystem/react-admin/BooleanField';

import { useConstantContext } from '../../ConstantsContext';
import resources from '../../../constants/resources';

const validateRequired = [required()];

const CompanyDetailsBusinessContent = () => {
  const { t } = useTranslation();
  const { entityRoles } = useRecordContext();
  const { constants } = useConstantContext();
  const resource = useResourceContext();

  const isCustomerPage = resource.endsWith(resources.CUSTOMER);
  const isBothCustomerAndMerchant = entityRoles.includes(constants.entityRoles.CUSTOMER)
    && entityRoles.includes(constants.entityRoles.MERCHANT);

  const shouldShowBankingDetails = isCustomerPage && !isBothCustomerAndMerchant;

  return (
    <>
      <Grid container>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="mainAddress.name" label={t('Name')} validate={validateRequired} />
          </SimpleShowLabeled>
        </Grid>
        {shouldShowBankingDetails && (
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField source="iban" label="IBAN" emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
        )}
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <NumberField source="staffHeadcount" label={t('Staff headcount')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="mainAddress.streetAddress" label={t('Street address')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        {shouldShowBankingDetails && (
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField source="bic" label="BIC" emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
        )}
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <NumberField source="annualTurnover" label={t('Annual turnover')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="mainAddress.additionalStreetAddress" label={t('Additional street address')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        {shouldShowBankingDetails && (
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField source="bankgiro" label={t('Bankgiro')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
        )}
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <NumberField source="balanceSheetTotal" label={t('Balance sheet total')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="mainAddress.town" label={t('Town')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="invoiceEmail" label={t('Invoice email')} type="email" emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="naceCode" label={t('NACE code')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="mainAddress.postcode" label={t('Postcode')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="contactEmail" label={t('Contact email')} type="email" emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="sector" label={t('Sector')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <TextField source="phone" type="tel" emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
      </Grid>
      <Box display="flex" flexDirection="column" marginTop="3.5rem">
        <TitleH5 title={t('Additional information')} />
        <BooleanField label={t('Is a small or medium enterprise')} source="isSME" />
        <BooleanField label={t('Is related or same entity group')} source="isRelated" />
      </Box>
    </>
  );
};

export default CompanyDetailsBusinessContent;
