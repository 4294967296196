import React, { useCallback } from 'react';
import { useRecordContext } from 'react-admin';
import { useTranslation } from 'react-i18next';

import { EditModalButton } from '../../designSystem/ModalButton';
import TextInput from '../../designSystem/react-admin/inputs/TextInput';
import InputsGroup from '../../designSystem/InputsGroup';

import { bicValidation, ibanValidation, bankgiroValidation } from '../../../utils/validationErrors';
import useCustomRpc from '../../hooks/useCustomRpc';
import useHasUserPermissions from '../../hooks/useHasUserPermissions';
import { useConstantContext } from '../../ConstantsContext';

const UpdateBankAccountsButton = () => {
  const record = useRecordContext();
  const { id } = record;
  const { t } = useTranslation();
  const { constants } = useConstantContext();
  const { userActions } = constants;

  const canEdit = useHasUserPermissions(
    constants,
    userActions.CREATE_MERCHANTS,
  );

  const {
    mutate: updateBankAccounts,
    isLoading,
  } = useCustomRpc({
    path: `entity-v2/merchant/${id}/bank-accounts`,
    httpMethod: 'PUT',
    shouldRefresh: true,
    errorMessage: t('Could not update bank accounts'),
  });

  const onSubmit = useCallback((formData) => {
    const transformedData = {
      ...formData,
      bankgiro: formData.bankgiro ? formData.bankgiro.replace('-', '') : null,
    };
    updateBankAccounts(transformedData);
    return true;
  }, [updateBankAccounts]);

  if (!canEdit) return null;

  return (
    <EditModalButton
      modalTitle={t('Update assigned bank accounts')}
      onClick={onSubmit}
      disabled={isLoading}
      width="47.5rem"
      formDefaultValue={{
        bankgiro: record.bankgiro,
        bic: record.bic,
        iban: record.iban,
      }}
    >
      <InputsGroup layout="column">
        <TextInput
          source="bankgiro"
          label={t('Bankgiro')}
          validate={bankgiroValidation}
        />
        <TextInput
          source="bic"
          label="BIC"
          validate={bicValidation}
        />
        <TextInput
          source="iban"
          label="IBAN"
          validate={ibanValidation}
        />
      </InputsGroup>
    </EditModalButton>
  );
};

export default UpdateBankAccountsButton;
