import { createTheme } from '@mui/material/styles';

import chartStyle from './components/charts/style';

export const DATE_FORMAT = 'DD, MMM YYYY';
export const DATE_WITH_HOUR_FORMAT = 'DD MMM, YYYY, hh:mm:ss';

const FONT_FAMILY = '"Poppins", "Noto Sans", "sans-serif"';

const PRIMARY_COLOR = '#1A314C';
const SECONDARY_COLOR = '#1755F4';
const BACKGROUND_COLOR = '#F7F5F2';
const GREY_COLOR = '#0000001a';
const LIGHT_GREY_COLOR = '#00000060';
const GREY_COLOR_INPUT = '#F5F5F5';
const GREY_COLOR_SELECT = '#EDEDED';
const HEADERS_COLOR = '#00000099';
const BOXES_COLOR = '#FFFFFF';
const BACKGROUND_HOVER_COLOR = '#F5F8FF';
const SWITCH_COLOR = '#212121';
const SWITCH_CHECKED_COLOR = '#00D293';
const SWITCH_TRACK_COLOR = '#E8E8E8';
const SWITCH_TRACK_CHECKED_COLOR = '#CCECC6';
const LIST_ACTIVE = '#E5EDFF';
const ERROR_BACKGROUND = '#FFE4E4';
const WARNING = '#ed6c02';
const WARNING_CONTENT_COLOR = '#C52506';
const WARNING_BACKGROUND_COLOR = '#FFE3DE';

const easeInOutTransition = '250ms cubic-bezier(0.4, 0, 0.2, 1)';

const transitions = {
  buttonHover: [
    'background-color',
    'box-shadow',
    'border-color',
    'color',
    'fill',
  ].map((property) => `${property} ${easeInOutTransition}`).join(','),
};

export const titleH1Style = {
  fontSize: '1.5rem', // Default to 15px if fontSize is not provided
  fontWeight: 600,
  color: 'switch',
};

export const titleH3Style = {
  fontSize: '15px',
  fontWeight: 600,
  color: 'switch',
};

export const titleH4Style = {
  fontSize: '12px',
  fontWeight: 500,
  color: 'switch',
};

const buttonVariants = {
  smallButton: {
    marginLeft: '1rem',
    maxHeight: '1.25rem',
    alignSelf: 'center',
    backgroundColor: GREY_COLOR,
  },
  subAction: {
    color: 'inherit',
    backgroundColor: 'transparent',
    justifyContent: 'start',
    margin: '0.2rem 0',
    padding: 0,
    ':hover': {
      color: SECONDARY_COLOR,
      backgroundColor: 'transparent',
    },
  },
  blueBackgroundOnHover: {
    // Re-apply Mui button design in case we use this variant for texts.
    cursor: 'pointer',
    verticalAlign: 'middle',
    lineHeight: '1.75',
    minWidth: '64px',
    textTransform: 'none',
    borderRadius: '0.8rem',
    fontWeight: '600',
    fontSize: '12px',
    boxShadow: 'unset!important',
    color: SECONDARY_COLOR,

    // Our design
    backgroundColor: 'transparent',
    '& svg path': {
      fill: SECONDARY_COLOR,
      transition: transitions.buttonHover,
    },
    justifyContent: 'center',
    transition: transitions.buttonHover,
    margin: '0.2rem 0',
    ':hover': {
      color: 'white',
      backgroundColor: SECONDARY_COLOR,
      '& svg path': {
        fill: 'white',
      },
    },
    paddingRight: '0.4rem',
    paddingLeft: '0.4rem',
    paddingTop: '0.3rem',
    paddingBottom: '0.3rem',
    '& .MuiButton-startIcon': {
      marginLeft: 0,
    },
  },
  blueBackgroundOnHoverContained: {
    backgroundColor: BACKGROUND_COLOR,
    padding: '0.25rem 0.75rem',
    minWidth: 'unset',
    '& svg path': {
      fill: SECONDARY_COLOR,
      transition: transitions.buttonHover,
    },
    justifyContent: 'center',
    transition: transitions.buttonHover,
    ':hover': {
      color: 'white',
      backgroundColor: SECONDARY_COLOR,
      '& svg path': {
        fill: 'white',
      },
    },
  },
  clickableLinkInPage: {
    fontWeight: '600',
    color: SECONDARY_COLOR,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  userMenuItem: {
    fontSize: '15px',
    fontWeight: '400',
    color: SWITCH_COLOR,
    paddingLeft: 0,
    paddingBottom: 0,
    paddingTop: '0.5rem',
    '& .RaMenuItemLink-icon, .MuiListItemIcon-root': {
      minWidth: 'unset',
      marginRight: '0.3rem',
    },
    ':hover': {
      backgroundColor: 'transparent',
      '& .MuiSvgIcon-root': {
        path: {
          fill: SECONDARY_COLOR,
        },
      },
    },
    '& .MuiSvgIcon-root': {
      width: '0.875rem',
      height: '0.875rem',
      color: '#B4AEA6',
    },
  },
};
buttonVariants.blueBackgroundOnHoverContainedActive = {
  ...buttonVariants.blueBackgroundOnHoverContained,
  color: 'white',
  backgroundColor: SECONDARY_COLOR,
  '& svg path': {
    fill: 'white',
  },
};

global.Apex = {
  ...chartStyle,
  title: {
    ...chartStyle.title,
    style: {
      ...chartStyle.title.style,
      fontSize: '15px',
      color: HEADERS_COLOR,
    },
  },
  chart: {
    fontFamily: FONT_FAMILY,
  },
};

const theme = createTheme({
  palette: {
    primary: { main: PRIMARY_COLOR },
    secondary: { main: SECONDARY_COLOR },
    background: { default: BACKGROUND_COLOR },
    grey: {
      main: GREY_COLOR,
      light: LIGHT_GREY_COLOR,
      input: GREY_COLOR_INPUT,
      select: GREY_COLOR_SELECT,
    },
    headers: HEADERS_COLOR,
    boxes: BOXES_COLOR,
    hover: BACKGROUND_HOVER_COLOR,
    switch: SWITCH_COLOR,
    switchChecked: SWITCH_CHECKED_COLOR,
    switchTrack: SWITCH_TRACK_COLOR,
    switchCheckedTrack: SWITCH_TRACK_CHECKED_COLOR,
    listActive: LIST_ACTIVE,
    errorBackground: ERROR_BACKGROUND,
    warning: {
      main: WARNING,
      content: WARNING_CONTENT_COLOR,
      background: WARNING_BACKGROUND_COLOR,
    },
  },
  typography: { fontFamily: FONT_FAMILY },
  components: {
    MuiTable: {
      styleOverrides: { root: { width: '100% !important' } },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          marginBottom: '0.5rem',
        },
      },
    },
    MuiInput: {
      styleOverrides: { root: { minWidth: 150 } },
    },
    MuiSelect: {
      styleOverrides: { root: { minWidth: 150 } },
    },
    MuiTextField: {
      styleOverrides: { root: { width: '15rem' } },
      defaultProps: { variant: 'filled', size: 'small' },
    },
    MuiTabs: {
      defaultProps: { variant: 'scrollable' },
      styleOverrides: {
        indicator: {
          backgroundColor: SECONDARY_COLOR,
          height: '0.25rem',
        },
      },
    },
    MuiGrid: {
      defaultProps: {
        lg: 12, md: 12, sm: 12, xs: 12, spacing: 2,
      },
    },
    RaFilterForm: {
      styleOverrides: { form: { marginTop: 0 } },
    },
    RaFileInput: {
      styleOverrides: { dropZone: { marginTop: 4 } },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#f7f7f7',
          '&.Mui-disabled': { backgroundColor: 'rgba(0, 0, 0, 0)' },
        },
      },
    },
    MuiScopedCssBaseline: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: { root: { '&.Mui-disabled': { color: 'rgba(0, 0, 0, 0.80)' } } },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderTop: 'dashed',
          borderTopWidth: '2px',
          borderColor: GREY_COLOR,
          margin: '0 1.5rem',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          '& .MuiStepLabel-iconContainer .Mui-completed': {
            color: SECONDARY_COLOR,
          },
          '& .MuiStepLabel-labelContainer .Mui-completed': {
            color: HEADERS_COLOR,
          },
          '& .MuiStepLabel-iconContainer .Mui-active': {
            color: LIGHT_GREY_COLOR,
          },
          '& .MuiStepLabel-labelContainer .Mui-active': {
            color: HEADERS_COLOR,
          },
        },
      },
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          '& > .MuiListItemIcon-root': {
            color: 'inherit',
          },
          '&.RaMenuItemLink-active': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
          },
        },
      },
    },
    RaDatagrid: {
      styleOverrides: {
        root: {
          '& .RaDatagrid-tableWrapper': {
            overflowX: 'auto',
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '& > * .RaDatagrid-rowCell': {
            borderBottom: 0,
          },
          '&:first-of-type': {
            borderTop: 0,
          },
          borderTop: `1px solid ${GREY_COLOR}`,
        },
      },
    },
    RaList: {
      styleOverrides: {
        root: {
          '& .RaList-main': {
            width: 0,
          },
        },
      },
    },
    RaTopToolbar: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiCard: {
      defaultProps: {
        color: 'boxes',
        elevation: 0,
      },
      styleOverrides: { root: { borderRadius: '10px' } },
    },
    MuiCardHeader: {
      styleOverrides: { root: { padding: '0rem 1rem' } },
    },
    MuiCardContent: {
      variants: [
        {
          props: { variant: 'pendingTask' },
          style: {
            '&:last-child': {
              padding: '0.563rem 2.75rem',
            },
          },
        },
        {
          props: { variant: 'currentExposure' },
          style: {
            '&:last-child': {
              padding: '2.563rem 1.25rem',
            },
          },
        },
        {
          props: { variant: 'currentExposureEntity' },
          style: {
            '&:last-child': {
              padding: '3.125rem 3.75rem',
            },
          },
        },
      ],
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          textTransform: 'none',
          borderRadius: '0.8rem',
          fontWeight: 600,
          fontSize: 12,
          boxShadow: 'none',
          ...ownerState.variant === 'contained' ? { padding: '0.3rem 0.75rem' } : {},
        }),
      },
      variants: Object.entries(buttonVariants).map(([variant, style]) => ({
        props: { variant },
        style,
      })),
    },
    RaConfirm: {
      styleOverrides: {
        root: { '& .RaConfirm-confirmPrimary': { color: '#fff' } },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '0.8rem',
          padding: '0.3rem 0.75rem',
        },
      },
    },
  },
});

export default theme;
