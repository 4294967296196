import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  BooleanField,
  WrapperField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import { useLocation } from 'react-router-dom';

import { AssignLabelButton, DeleteLabelButton } from './BulkOrderButtons';
import CurrencyField from '../utils/CurrencyField';
import ApplicationStatusChip from '../utils/ApplicationStatusChip';
import SnoozeChip from '../utils/SnoozeChip';
import LabelsChipGroup from '../utils/LabelsChipGroup';

import Datagrid, { DatagridConfigurable } from '../designSystem/react-admin/Datagrid';
import { EntityReferenceField } from '../designSystem/react-admin/ReferenceField';
import DateField from '../designSystem/react-admin/DateField';

import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';

import { useConstantContext } from '../ConstantsContext';

import resources from '../../constants/resources';

import useHasUserPermissions from '../hooks/useHasUserPermissions';
import OverdueDaysField from '../utils/OverdueDaysField';

const OrderListFields = ({ canEdit, filter }) => {
  const { t } = useTranslation();
  const { isFinancer } = useUserPermissionsAndRoles();
  const { constants } = useConstantContext();
  const { pathname } = useLocation();
  const { userActions } = constants;

  const canViewMerchant = useHasUserPermissions(
    constants,
    userActions.VIEW_MERCHANTS,
  );

  const canViewSnooze = useHasUserPermissions(
    constants,
    userActions.VIEW_SNOOZE,
  );

  const shouldShowMerchantsColumn = !filter.sellerId && canViewMerchant;
  const shouldShowCustomersColumn = !filter.debtorId;

  const isConfigurable = pathname.includes(resources.ORDER);
  const DatagridComponent = isConfigurable ? DatagridConfigurable : Datagrid;

  const columns = [
    <TextField key="orderReferenceNumber" source="orderReferenceNumber" label={t('Order reference')} sortable={false} />,
    shouldShowMerchantsColumn && (
      <EntityReferenceField
        key="sellerId"
        label={t('Merchant')}
        source="sellerId"
        reference="entity-v2/merchant"
        link={({ entityDetailsId }) => `/entity-v2/merchant/${entityDetailsId}`}
      />
    ),
    shouldShowCustomersColumn && (
      <EntityReferenceField
        key="buyerId"
        label={t('Customer')}
        source="buyerId"
        reference="entity-v2/customer"
        link={({ entityDetailsId }) => `/entity-v2/customer/${entityDetailsId}`}
      />
    ),
    <DateField key="activationDate" source="activationDate" label={t('Activation date')} />,
    <DateField key="financingDate" source="financing.financedAt" label={t('Financing date')} sortable={false} emptyText={t('Not financed')} />,
    <DateField key="dueDate" source="mainInvoiceDueDate" label={t('Due date')} sortable={false} style={{ whiteSpace: 'nowrap' }} />,
    <OverdueDaysField key="overdueDays" label={t('Due')} source="overdueDays" />,
    isFinancer && (
      <WrapperField
        key="labels"
        source="labels" // Necessary for hiding the column by default
        label={t('Labels')}
      >
        <LabelsChipGroup />
      </WrapperField>
    ),
    <BooleanField key="hasRecoursed" source="hasRecoursed" label={t('Recoursed')} sortable={false} />,
    <CurrencyField key="balance" source="balance" currencySource="currency" label={t('Balance')} />,
    <ApplicationStatusChip key="applicationStatus" label={t('Appl. status')} source="applicationStatus" sortable={false} subSource="partnerFinancing.partnerDecision" />,
    canViewSnooze && <SnoozeChip key="snooze" label={t('Norrsken')} source="hasSnoozeAttribute" />,
  ].filter(Boolean);

  return (
    <DatagridComponent
      rowClick={canEdit ? 'edit' : 'show'}
      bulkActionButtons={canEdit && isFinancer ? <><AssignLabelButton /><DeleteLabelButton /></> : false}
      omit={['financing.isFinanced', 'hasRecoursed', 'isAccountPaid', 'labels']}
      cellMaxWidth="10rem"
      rightAlignedColumns={['balance', 'applicationStatus', 'hasSnoozeAttribute']}
    >
      {columns}
    </DatagridComponent>
  );
};

OrderListFields.propTypes = {
  canEdit: PropTypes.bool,
  filter: PropTypes.shape({
    sellerId: PropTypes.string,
    debtorId: PropTypes.string,
  }),
};

OrderListFields.defaultProps = {
  canEdit: undefined,
  filter: {},
};

export default OrderListFields;
