import React from 'react';
import PropTypes from 'prop-types';
import { Grid, useTheme } from '@mui/material';

const SummaryGrid = ({ children }) => {
  const theme = useTheme();

  return (
    <Grid
      container
      display="flex"
      alignItems="start"
      backgroundColor={theme.palette.grey.input}
      borderRadius="0.625rem"
      marginY="0.5rem"
      ml="0"
      justifyContent="space-between"
      padding="0 0 1rem 0"
    >
      {children}
    </Grid>
  );
};

SummaryGrid.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SummaryGrid;
