import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useWatch, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  TextInput,
  AutocompleteInput,
  BooleanInput,
  required,
  NumberInput,
  useRecordContext,
} from 'react-admin';

import {
  bicValidation,
  emailValidation,
  ibanValidation,
  phoneValidation,
  positiveNumberValidation,
} from '../../utils/validationErrors';

import OldFieldsGroup from '../utils/OldFieldsGroup';

import { useConstantContext } from '../ConstantsContext';

const validateRequired = [required()];

const CompanyDetailsCreate = ({ enableAliasInput, userAction }) => {
  const { choices, constants } = useConstantContext();
  const entityDetailsRecord = useRecordContext();
  const { setValue } = useFormContext();
  const { t } = useTranslation();
  const { userActions } = constants;

  const choiceStates = choices;

  const coreTypeInput = useWatch({ name: 'type' });
  useEffect(() => {
    if (coreTypeInput === constants.coreTypes.CONSUMER) {
      setValue('staffHeadcount', undefined);
      setValue('annualTurnover', undefined);
      setValue('balanceSheetTotal', undefined);
      setValue('naceCode', undefined);
      setValue('sector', undefined);
      setValue('isRelated', undefined);
      setValue('isSME', undefined);
      setValue('keyPeople', []);
    }
  }, [setValue, coreTypeInput, constants]);

  if (!entityDetailsRecord && !coreTypeInput) return null;
  if (entityDetailsRecord && coreTypeInput) throw new Error('Cannot render entity details. One source of truth is required for the entity core type');

  let isBusiness;
  if (coreTypeInput) isBusiness = coreTypeInput === constants.coreTypes.BUSINESS;
  else {
    isBusiness = constants.coreTypes
      ? entityDetailsRecord.coreType === constants.coreTypes.BUSINESS
      : undefined;
  }

  const isMerchantCreate = userAction === userActions.CREATE_MERCHANTS;

  return (
    <OldFieldsGroup name={isBusiness ? t('Company Details') : t('Consumer Details')} withBorder column={4} fillAvailable>
      {enableAliasInput && <TextInput source="marketplaceReferenceId" label={t('Alias')} validate={validateRequired} />}
      <TextInput source="mainAddress.name" label={t('Name')} validate={validateRequired} />
      <TextInput source="mainAddress.streetAddress" label={t('Street Address')} />
      <TextInput source="mainAddress.additionalStreetAddress" label={t('Additional Street Address')} />
      <TextInput source="mainAddress.town" label={t('Town')} />
      <TextInput source="mainAddress.postcode" label={t('Postcode')} />
      <TextInput source="iban" label="IBAN" validate={[ibanValidation]} />
      <TextInput source="bic" label="BIC" validate={[bicValidation]} />
      {!isMerchantCreate && <TextInput source="bankgiro" label={t('Bankgiro')} />}
      <TextInput source="phone" type="tel" validate={[phoneValidation]} />
      <TextInput source="invoiceEmail" label={t('Invoice Email')} type="email" validate={[emailValidation]} />
      <TextInput source="contactEmail" label={t('Contact Email')} type="email" validate={[emailValidation]} />
      {isBusiness && <NumberInput source="staffHeadcount" label={t('Staff Headcount')} validate={[positiveNumberValidation]} />}
      {isBusiness && <NumberInput source="annualTurnover" label={t('Annual Turnover')} />}
      {isBusiness && <NumberInput source="balanceSheetTotal" label={t('Balance Sheet Total')} />}
      {isBusiness && <AutocompleteInput source="naceCode" label={t('NACE Code')} choices={choiceStates.naceCodes} />}
      {isBusiness && <AutocompleteInput source="sector" label={t('Sector')} choices={choiceStates.companySectors} />}
      {isBusiness && <BooleanInput source="isRelated" label={t('Is Related')} />}
      {isBusiness && <BooleanInput source="isSME" label={t('Is SME')} />}
    </OldFieldsGroup>
  );
};

CompanyDetailsCreate.propTypes = {
  enableAliasInput: PropTypes.bool,
  userAction: PropTypes.string,
};

CompanyDetailsCreate.defaultProps = {
  enableAliasInput: false,
  userAction: undefined,
};

export default CompanyDetailsCreate;
