import React from 'react';

import { useRecordContext, usePermissions } from 'react-admin';
import { Chip } from '@mui/material';
import ListChip, { colors } from '../designSystem/ListChip';

import { useConstantContext } from '../ConstantsContext';

import hasUserPermissions from '../../utils/hasUserPermissions';

export const SnoozeOrderTitleChip = () => {
  const record = useRecordContext();
  const { constants } = useConstantContext();
  const authenticationData = usePermissions();
  const { userActions } = constants;
  const { userAllowedPermissions } = authenticationData.permissions || {};

  const canView = hasUserPermissions(
    userAllowedPermissions,
    userActions.VIEW_SNOOZE,
  );

  const { hasSnoozeAttribute } = record || {};

  if (!canView || !hasSnoozeAttribute) return null;

  return (
    <Chip
      style={{
        marginRight: '5px',
        background: 'linear-gradient(to right, #285505, #8242A9)',
        color: 'white',
      }}
      label="Norrsken"
    />
  );
};

const SnoozeChip = () => {
  const record = useRecordContext();
  const { constants } = useConstantContext();
  const authenticationData = usePermissions();
  const { userActions } = constants;
  const { userAllowedPermissions } = authenticationData.permissions || {};

  const canView = hasUserPermissions(
    userAllowedPermissions,
    userActions.VIEW_SNOOZE,
  );

  const { hasSnoozeAttribute } = record || {};

  if (!canView || !hasSnoozeAttribute) return null;

  return (
    <ListChip
      label="N"
      chipColor={colors.purple}
      style={{
        borderRadius: '50%',
        width: '1.9rem',
        height: '1.9rem',
        minWidth: '1.9rem',
      }}
    />
  );
};

export default SnoozeChip;
