import React from 'react';
import {
  TextField,
  FunctionField,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import FieldsGroup from '../../utils/FieldsGroup';
import SimpleShowLabeled from '../../utils/SimpleShowLabeled';
import CurrencyField from '../../utils/CurrencyField';

import ConfirmKycButton from './ConfirmKycButton';

import useUserPermissionsAndRoles from '../../hooks/useUserPermissionsAndRoles';

import BooleanField from '../../designSystem/react-admin/BooleanField';
import TitleH3 from '../../designSystem/TitleH3';
import DateField from '../../designSystem/react-admin/DateField';

import { useConstantContext } from '../../ConstantsContext';

const renderCreditScore = (record) => {
  if (!record) return '';
  const { creditScore, creditScoreProvider } = record || {};
  if (!creditScore || !creditScoreProvider) return '-';
  return `${creditScore} (${creditScoreProvider})`;
};
const renderPaymentScore = (record) => {
  if (!record) return '';
  const { paymentScore, paymentScoreProvider } = record || {};
  if (!paymentScore || !paymentScoreProvider) return '-';
  return `${paymentScore} (${paymentScoreProvider})`;
};

const KycInformation = () => {
  const { isFinancer } = useUserPermissionsAndRoles();
  const record = useRecordContext();
  const { t } = useTranslation();
  const { clientInfo } = useConstantContext();

  const pendingReview = !!record.pendingPeerReviewRequest;

  return (
    isFinancer && (
      <FieldsGroup
        title={t('Insurance and KYC')}
        actions={!pendingReview && <ConfirmKycButton />}
      >
        <Grid container>
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <DateField showTime source="lastKycDoneDate" label={t('Last update')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>

          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <FunctionField render={renderCreditScore} label={t('Credit score')} />
            </SimpleShowLabeled>
          </Grid>

          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <FunctionField render={renderPaymentScore} label={t('Payment score')} />
            </SimpleShowLabeled>
          </Grid>

          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <DateField source="insuranceStartDate" label={t('Insurance start date')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>

          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <DateField source="insuranceEndDate" label={t('Insurance end date')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>

          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <TextField source="financerScore" label={t('Financer score')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>

          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <CurrencyField source="expectedVolume" label={t('Expected monthly order volume')} emptyText="-" currency={clientInfo.referenceCurrency} />
            </SimpleShowLabeled>
          </Grid>
        </Grid>
        <Box margin="3.5rem 0rem">
          <TitleH3 title={t('Rating')} />
          <Grid container spacing={2}>
            <Grid item lg={4} md={6}>
              <SimpleShowLabeled>
                <TextField source="ratingProvider" label={t('Rating provider')} emptyText="-" />
              </SimpleShowLabeled>
            </Grid>

            <Grid item lg={4} md={6}>
              <SimpleShowLabeled>
                <TextField source="rating" label={t('Rating')} emptyText="-" />
              </SimpleShowLabeled>
            </Grid>

            <Grid item lg={4} md={6}>
              <SimpleShowLabeled>
                <TextField source="creditQualityStep" label={t('Credit quality step (1-6)')} emptyText="-" />
              </SimpleShowLabeled>
            </Grid>
          </Grid>
        </Box>
        <Box display="flex" flexDirection="column" margin="1rem 0rem">
          <BooleanField source="nonInsurableCompany" label={t('Governmental entity')} />
          <BooleanField source="financialInstitution" label={t('Financial institution')} />
          <BooleanField source="privateBusiness" label={t('Private business')} />
        </Box>
      </FieldsGroup>
    )
  );
};

export default KycInformation;
