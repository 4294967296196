import React from 'react';
import {
  TextField,
  ReferenceField,
} from 'react-admin';

import Grid from '@mui/material/Grid';

import { useTranslation } from 'react-i18next';

import { EntityReferenceField } from '../../designSystem/react-admin/ReferenceField';

import FieldsGroup from '../../utils/FieldsGroup';
import CurrencyField from '../../utils/CurrencyField';
import SimpleShowLabeled from '../../utils/SimpleShowLabeled';

import useUserPermissionsAndRoles from '../../hooks/useUserPermissionsAndRoles';
import useHasUserPermissions from '../../hooks/useHasUserPermissions';

import { useConstantContext } from '../../ConstantsContext';

const CustomerDetailsInApplication = () => {
  const { t } = useTranslation();
  const { isFinancer, isMerchant } = useUserPermissionsAndRoles();
  const { constants } = useConstantContext();
  const { userActions } = constants;

  const canViewAssignedCreditLimit = useHasUserPermissions(
    constants,
    userActions.VIEW_CUSTOMERS,
  ) && isMerchant;
  const canViewCreditInfo = useHasUserPermissions(
    constants,
    userActions.VIEW_CUSTOMERS,
  ) && isFinancer;

  return (
    <FieldsGroup title={t('Customer details')}>
      <Grid container>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <EntityReferenceField
              label={t('Customer')}
              source="buyerId"
              reference="entity-v2/customer"
              link={({ entityDetailsId }) => `/entity-v2/customer/${entityDetailsId}`}
              isPage
            />
          </SimpleShowLabeled>
        </Grid>
        {canViewAssignedCreditLimit && (
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <ReferenceField
              label={t('Credit limit')}
              source="buyerId"
              reference="entity-v2/reference"
              link={false}
            >
              <ReferenceField
                source="entityDetailsId"
                reference="entity-v2/assigned-credit-info"
                link={false}
              >
                <CurrencyField source="assignedCreditLimit" currencySource="assignedCreditLimitCurrency" />
              </ReferenceField>
            </ReferenceField>
          </SimpleShowLabeled>
        </Grid>
        )}
        {canViewAssignedCreditLimit && (
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <ReferenceField
              label={t('Available credit limit')}
              source="buyerId"
              reference="entity-v2/reference"
              link={false}
            >
              <ReferenceField
                source="entityDetailsId"
                reference="entity-v2/assigned-credit-info"
                link={false}
              >
                <CurrencyField source="assignedCreditLimitAvailable" currencySource="assignedCreditLimitCurrency" />
              </ReferenceField>
            </ReferenceField>
          </SimpleShowLabeled>
        </Grid>
        )}
        {canViewCreditInfo && (
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <ReferenceField
              label={t('Credit limit')}
              source="buyerId"
              reference="entity-v2/reference"
              link={false}
            >
              <ReferenceField
                source="entityDetailsId"
                reference="entity-v2/credit-info"
                link={false}
              >
                <CurrencyField source="creditLimit" currencySource="creditLimitCurrency" />
              </ReferenceField>
            </ReferenceField>
          </SimpleShowLabeled>
        </Grid>
        )}
        {canViewCreditInfo && (
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <ReferenceField
                label={t('Available credit limit')}
                source="buyerId"
                reference="entity-v2/reference"
                link={false}
              >
                <ReferenceField
                  source="entityDetailsId"
                  reference="entity-v2/credit-info"
                  link={false}
                >
                  <CurrencyField source="creditLimitAvailable" currencySource="creditLimitCurrency" />
                </ReferenceField>
              </ReferenceField>
            </SimpleShowLabeled>
          </Grid>
        )}
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <ReferenceField
              label={t('Customer email')}
              source="buyerId"
              reference="entity-v2/reference"
              link={false}
            >
              <ReferenceField
                source="entityDetailsId"
                reference="entity-v2/customer"
                link={false}
              >
                <TextField source="contactEmail" emptyText="-" />
              </ReferenceField>
            </ReferenceField>
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <ReferenceField
              label={t('Customer phone number')}
              source="buyerId"
              reference="entity-v2/reference"
              link={false}
            >
              <ReferenceField
                source="entityDetailsId"
                reference="entity-v2/customer"
                link={false}
              >
                <TextField source="phone" emptyText="-" />
              </ReferenceField>
            </ReferenceField>
          </SimpleShowLabeled>
        </Grid>
      </Grid>
    </FieldsGroup>
  );
};

export default CustomerDetailsInApplication;
