import { styled } from '@mui/material';
import MuiChip from '@mui/material/Chip';

export const colors = {
  red: {
    text: '#F95738',
    background: '#FFE3DE',
  },
  green: {
    text: '#093E00',
    background: '#CCECC7',
  },
  orange: {
    text: '#4B3F00',
    background: '#FFF0BA',
  },
  gray: {
    text: 'white',
    background: 'grey',
  },
  blue: {
    text: 'black',
    background: '#DBE1F3',
  },
  purple: {
    text: '#FFFFFF',
    background: '#7E0092',
  },
};

const StyledChip = styled(MuiChip, {
  shouldForwardProp: (prop) => (
    prop !== 'chipColor'
    || prop !== 'labelWidth'
    || prop !== 'reducePadding'
  ),
})(({ chipColor, labelWidth, reducePadding }) => ({
  backgroundColor: chipColor.background,
  fontSize: '10px',
  fontWeight: '600',
  padding: reducePadding ? '0.25rem' : '0.25rem 0.75rem',
  height: 'auto',
  width: labelWidth || '5rem',
  '& .MuiChip-label': {
    padding: 0,
    textOverflow: 'unset',
    color: chipColor.text,
  },
}));

const ListChip = StyledChip;

export default ListChip;
