import React from 'react';
import {
  ReferenceField as RaReferenceField,
  TextField,
  useRecordContext,
} from 'react-admin';
import { styled } from '@mui/material';
import PropTypes from 'prop-types';
import { useConstantContext } from '../../ConstantsContext';

export const EntityReferenceFieldComponent = () => {
  const record = useRecordContext();

  if (!record) return null;

  const { mainAddress, marketplaceReferenceId } = record;

  if (mainAddress && mainAddress.name) return <TextField source="mainAddress.name" />;
  if (marketplaceReferenceId) return <TextField source="marketplaceReferenceId" />;

  return null;
};

export const getLink = (entityDetailsId, entityRoles, constantRoles) => {
  if (entityRoles.includes(constantRoles.MERCHANT)) {
    return `/entity-v2/merchant/${entityDetailsId}`;
  }
  if (entityRoles.includes(constantRoles.CUSTOMER)) {
    return `/entity-v2/customer/${entityDetailsId}`;
  }
  return false;
};

const StyledReferenceField = styled(RaReferenceField)(({ theme, isPage }) => ({
  '& .RaReferenceField-link': {
    '& > span': {
      ...(isPage
        ? theme.components.MuiButton.variants
          .find(({ props: { variant } }) => variant === 'clickableLinkInPage').style
        : {
          ...theme.components.MuiButton.variants
            .find(({ props: { variant } }) => variant === 'blueBackgroundOnHover').style,
        }
      ),
    },
  },
}));

const ReferenceField = ({
  referenceSource,
  reference,
  resource,
  link,
  source,
  FieldComponent,
  FieldComponentProps,
  isPage,
}) => (
  <StyledReferenceField
    source={referenceSource}
    reference={reference}
    resource={resource}
    link={link}
    emptyText="-"
    isPage={isPage}
  >
    <FieldComponent
      // We want the source to be read by React admin when reading the children props,
      // hence we don't want ot in the FieldComponentProps
      source={source}

      // eslint-disable-next-line react/jsx-props-no-spreading
      {...FieldComponentProps}
    />
  </StyledReferenceField>
);

ReferenceField.propTypes = {
  referenceSource: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  resource: PropTypes.string,
  link: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.oneOf([false]),
  ]),
  source: PropTypes.string.isRequired,
  FieldComponent: PropTypes.elementType.isRequired,
  FieldComponentProps: PropTypes.shape(),
  sortable: PropTypes.bool,
  isPage: PropTypes.bool,
};
ReferenceField.defaultProps = {
  link: false,
  FieldComponentProps: {},
  sortable: false,
  resource: undefined,
  isPage: false,
};

export const EntityReferenceField = ({
  source,
  reference,
  link,
  isPage,
}) => {
  const { constants } = useConstantContext();
  const { entityRoles: constantRoles } = constants;

  const getEntityLink = link || (({ entityDetailsId, entityRoles }) => getLink(
    entityDetailsId,
    entityRoles,
    constantRoles,
  ));

  return (
    <ReferenceField
      referenceSource={source}
      source="entityDetailsId"
      reference="entity-v2/reference"
      FieldComponent={ReferenceField}
      FieldComponentProps={{
        referenceSource: 'entityDetailsId',
        reference,
        link: getEntityLink,
        source: '', // Edge case where the FieldComponent doesn't need a source
        FieldComponent: EntityReferenceFieldComponent,
        isPage,
      }}
      isPage={isPage}
    />
  );
};
EntityReferenceField.propTypes = {
  source: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  link: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.oneOf([false]),
  ]),
  isPage: PropTypes.bool,
};
EntityReferenceField.defaultProps = {
  link: false,
  isPage: false,
};

export default ReferenceField;
