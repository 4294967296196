import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';

import { useConstantContext } from '../ConstantsContext';
import { ProcessableFileUploadModalButton } from '../designSystem/ModalButton';
import useDownloadDocument from '../hooks/useDownloadDocument';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

const DownloadSnoozeFileTemplate = (props) => {
  const { t } = useTranslation();

  const {
    mutate: downloadSnoozeFileTemplate,
  } = useDownloadDocument({
    path: 'order/snooze-file-template',
    errorMessage: t('Could not retrieve Norrsken file template'),
  });

  const downloadTemplate = useCallback(async (e) => {
    e.preventDefault();
    downloadSnoozeFileTemplate();
  }, [downloadSnoozeFileTemplate]);

  return (
    <Button
      onClick={downloadTemplate}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {t('Download Norrsken file template')}
    </Button>
  );
};

const ImportSnoozeButton = () => {
  const {
    constants,
  } = useConstantContext();
  const { userActions } = constants;
  const { t } = useTranslation();

  const canCreate = useHasUserPermissions(
    constants,
    userActions.MANAGE_SNOOZE,
  );
  if (!canCreate) return null;

  return (
    <ProcessableFileUploadModalButton
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      uploadPath="upload/snooze-file"
      ModalButtonProps={{
        modalTitle: t('Upload Norrsken file'),
        openButtonLabel: t('+ Norrsken'),
        actionLabel: t('Upload'),
        formDefaultValue: {},
        sx: {
          background: 'linear-gradient(to right, #285505, #8242A9)',
          color: 'white',
        },
      }}
      templateDownloadButton={<DownloadSnoozeFileTemplate />}
      informationText={t('Only orders already created will change. To make sure the data is converted correct you can use the template below')}
    />
  );
};

export default ImportSnoozeButton;
