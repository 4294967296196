import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import renderReference from '../../../utils/renderReference';

import useUserPermissionsAndRoles from '../../hooks/useUserPermissionsAndRoles';
import { useConstantContext } from '../../ConstantsContext';
import DateInput from '../../designSystem/react-admin/inputs/DateInput';
import ReferenceArrayInput from '../../designSystem/react-admin/inputs/ReferenceArrayInput';
import AutocompleteArrayInput from '../../designSystem/react-admin/inputs/AutocompleteArrayInput';
import { FinancingStatusInput, PaymentStatusInput } from './useInvoicingFilters';

const NorraInvoicingFilters = () => {
  const { constants } = useConstantContext();
  const { entityRoles } = constants;
  const { t } = useTranslation();

  const { isFinancer } = useUserPermissionsAndRoles();
  const { watch } = useFormContext();
  const paidSelect = watch('filters.orderStatus') === 'paid';
  const financedSelect = watch('filters.financed') === 'true';

  const [invoicingFilters, setInvoicingFilters] = useState([]);

  useEffect(() => {
    setInvoicingFilters([
      <ReferenceArrayInput
        source="filters.debtor"
        label={t('Customer')}
        reference="entity-v2/reference"
        resource="/entity-v2/reference"
        filter={{ entityRoles: entityRoles.CUSTOMER }}
      >
        <AutocompleteArrayInput
          optionText={renderReference}
          label={t('Customer')}
          suggestionLimit={20}
          options={{
            suggestionsContainerProps: {
              style: { zIndex: 1500 },
            },
          }}
        />
      </ReferenceArrayInput>,
      <DateInput
        source="filters.issueDateFrom"
        label={t('Issue date (from)')}
      />,
      <DateInput
        source="filters.issueDateTo"
        label={t('Issue date (to)')}
      />,
      <DateInput
        source="filters.createdDateFrom"
        label={t('Creation date (from)')}
      />,
      <DateInput
        source="filters.createdDateTo"
        label={t('Creation date (to)')}
      />,
      <DateInput
        source="filters.dueFrom"
        label={t('Due date (from)')}
      />,
      <DateInput
        source="filters.dueTo"
        label={t('Due date (to)')}
      />,
      <FinancingStatusInput
        source="filters.financed"
        label={t('Financing status')}
      />,
      financedSelect && (
        <DateInput
          source="filters.financeFrom"
          label={t('Financing date (from)')}
        />
      ),
      financedSelect && (
        <DateInput
          source="filters.financeTo"
          label={t('Financing date (to)')}
        />
      ),
      <PaymentStatusInput
        source="filters.orderStatus"
        label={t('Payment status')}
      />,
      paidSelect && (
        <DateInput
          source="filters.paidFrom"
          label={t('Paid date (From)')}
        />
      ),
      paidSelect && (
        <DateInput
          source="filters.paidTo"
          label={t('Paid date (to)')}
        />
      ),
    ].filter((input) => !!input));
  }, [
    t,
    paidSelect,
    financedSelect,
    isFinancer,
    entityRoles,
  ]);

  return [invoicingFilters, setInvoicingFilters];
};

export default NorraInvoicingFilters;
