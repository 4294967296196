import React from 'react';
import {
  TextField,
  ArrayField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';

import CurrencyField from './CurrencyField';
import PercentageField from './PercentageField';

import Datagrid from '../designSystem/react-admin/Datagrid';

import resources from '../../constants/resources';
import SimpleShowLabeledList from './SimpleShowLabeledList';
import { useConstantContext } from '../ConstantsContext';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

const FeeGrid = ({ label, source }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { constants } = useConstantContext();
  const { userActions } = constants;
  const shouldDisplayFeeCurrency = pathname.includes(resources.MERCHANT) || pathname.includes('my-company');
  const hasSnoozePermission = useHasUserPermissions(
    constants,
    userActions.VIEW_SNOOZE,
  );
  return (
    <Box overflow="auto" whiteSpace="nowrap">
      <SimpleShowLabeledList fullWidth>
        <ArrayField label={label} source={source}>
          <Datagrid bulkActionButtons={false} rightAlignedColumns={['percentageValue', 'cap\\.value', 'vatPercentage', 'deferralInterestPercentage']}>
            {shouldDisplayFeeCurrency && <TextField source="currency" label={t('Currency')} emptyText="-" />}
            <TextField source="rateType" label={t('Rate')} emptyText="-" />
            <TextField source="feeTrigger" label={t('Trigger')} emptyText="-" />
            <TextField source="billingMethod" label={t('Billing method')} emptyText="-" />
            <TextField source="feeReferenceAmount" label={t('Reference amount')} emptyText="-" />
            <TextField source="dayCountConvention" label={t('Day count convention')} emptyText="-" />
            <PercentageField source="percentageValue" label={`${t('Fee')} (%)`} emptyText="-" />
            <CurrencyField source="cap.value" currencySource="currency" label={t('Fee cap')} emptyText="-" />
            <PercentageField source="vatPercentage" label={`${t('VAT')} (%)`} emptyText="-" />
            {hasSnoozePermission && <PercentageField source="deferralInterestPercentage" label={t('Deferral')} emptyText="-" />}
          </Datagrid>
        </ArrayField>
      </SimpleShowLabeledList>
    </Box>
  );
};

FeeGrid.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string.isRequired,
};

FeeGrid.defaultProps = {
  label: undefined,
};

export default FeeGrid;
