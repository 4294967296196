import React, {
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import {
  required,
  useRecordContext,
  ArrayInput,
} from 'react-admin';
import {
  useWatch,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import {
  Typography,
  Grid,
} from '@mui/material';
import { ChildrenProp } from '../../utils/helpers/propTypes';

import ModalButton from '../designSystem/ModalButton';
import TextInput from '../designSystem/react-admin/inputs/TextInput';
import ReferenceInput from '../designSystem/react-admin/inputs/ReferenceInput';
import AutocompleteInput from '../designSystem/react-admin/inputs/AutocompleteInput';
import SimpleFormIterator from '../designSystem/react-admin/SimpleFormIterator';
import InputsGroup from '../designSystem/InputsGroup';
import TooltipBox from '../designSystem/TooltipBox';
import SummaryGrid from '../designSystem/SummaryGrid';
import { useConstantContext } from '../ConstantsContext';
import NumberInput from '../designSystem/react-admin/inputs/NumberInput';
import WarningBox from '../designSystem/WarningBox';
import useCustomRpc from '../hooks/useCustomRpc';
import useHasUserPermissions from '../hooks/useHasUserPermissions';
import roundNumber from '../../utils/roundNumber';

import CurrencyField, { colorSwticherFlag } from '../utils/CurrencyField';
import renderReference from '../../utils/renderReference';
import { nonZeroNumberValidation, twoDecimalsValidation } from '../../utils/validationErrors';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

const validateRequired = [required()];

const AssignedPaymentContext = React.createContext({});

const AssignedPaymentContextProvider = ({ children }) => {
  const [leftToAssign, setLeftToAssign] = useState(0);
  const [hasError, setHasWrongSign] = useState(false);

  return (
    <AssignedPaymentContext.Provider value={{
      leftToAssign,
      setLeftToAssign,
      hasError,
      setHasWrongSign,
    }}
    >
      {children}
    </AssignedPaymentContext.Provider>
  );
};

AssignedPaymentContextProvider.propTypes = {
  children: ChildrenProp.isRequired,
};

const useAssignedPaymentContext = () => {
  const AssignedPayment = useContext(AssignedPaymentContext);
  return AssignedPayment;
};

const AssignTotalAmountBox = ({
  amount,
  currency,
}) => {
  const { t } = useTranslation();
  const {
    setLeftToAssign,
    setHasWrongSign,
  } = useAssignedPaymentContext();

  const { control } = useFormContext();
  const payments = useWatch({ control, name: 'payments' });

  const isPositiveTotalAmount = amount > 0;

  const assignedAmount = (payments || []).reduce((sum, payment) => {
    const paymentAmount = payment.amount ? payment.amount : 0;
    return nonZeroNumberValidation(paymentAmount) ? sum : sum + paymentAmount;
  }, 0);

  const leftToAssign = roundNumber(amount - assignedAmount, 2);
  const displayedLeftToAssign = isPositiveTotalAmount
    ? Math.max(leftToAssign, 0)
    : Math.min(leftToAssign, 0);
  const outsideTotalAmountThreshold = isPositiveTotalAmount
    ? assignedAmount > amount || assignedAmount < 0
    : assignedAmount < amount || assignedAmount > 0;

  const hasWrongSign = (payments || []).some((payment) => (payment.amount
    && (payment.amount >= 0) !== isPositiveTotalAmount));

  useEffect(() => {
    setLeftToAssign(leftToAssign);
    setHasWrongSign(hasWrongSign);
  }, [amount, payments, hasWrongSign, setLeftToAssign, setHasWrongSign, leftToAssign]);

  return (
    <SummaryGrid>
      <Grid item lg={4} md={6} flex="flex" whiteSpace="nowrap" textAlign="left">
        <SimpleShowLabeled>
          <CurrencyField
            label={t('Total amount')}
            customRecord={{ totalAmount: amount }}
            source="totalAmount"
            currency={currency}
            fontWeight="600"
          />
        </SimpleShowLabeled>
      </Grid>
      <Grid item lg={4} md={6} mr="1rem" textAlign="left">
        <SimpleShowLabeled>
          <CurrencyField
            label={t('Left to assign')}
            customRecord={{
              displayedLeftToAssign,
              currency,
            }}
            fontWeight="600"
            source="displayedLeftToAssign"
            currencySource="currency"
            colorSwticherFlag={outsideTotalAmountThreshold
              ? colorSwticherFlag.UNIDENTIFIED_PAYMENT
              : undefined}
          />
        </SimpleShowLabeled>
      </Grid>
    </SummaryGrid>
  );
};

AssignTotalAmountBox.propTypes = {
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};

const IdentifyButton = () => {
  const { id, balance, currency } = useRecordContext();
  const { t } = useTranslation();
  const { constants } = useConstantContext();
  const { userActions, entityRoles } = constants;
  const {
    leftToAssign,
    setLeftToAssign,
    hasError,
    setHasWrongSign,
  } = useAssignedPaymentContext();

  const amount = -balance;

  const canManage = useHasUserPermissions(
    constants,
    userActions.MANAGE_UNIDENTIFIED_PAYMENTS,
  );

  const {
    mutate: identifyUnidentifiedPayment,
    isLoading,
  } = useCustomRpc({
    path: `unidentified-account/${id}/identify`,
    httpMethod: 'POST',
    shouldRefresh: true,
    errorMessage: t('Failed to identify the payment'),
    successMessage: t('Payment identified successfully'),
  });

  const onSubmitIdentify = useCallback(({ merchantId, payments }) => {
    identifyUnidentifiedPayment({ merchantId, payments });
    return true;
  }, [identifyUnidentifiedPayment]);

  if (!canManage || !amount) return null;

  return (
    <ModalButton
      modalTitle={t('Identify')}
      onClick={onSubmitIdentify}
      disabled={isLoading || leftToAssign !== 0 || hasError}
      openButtonLabel={t('Identify')}
      actionLabel={t('Identify')}
      width="48rem"
      formDefaultValue={{
        payments: [{ amount: roundNumber(amount) }],
      }}
      onClose={() => {
        setLeftToAssign(0);
        setHasWrongSign(false);
      }}
      color="secondary"
      sx={{ padding: '0.5rem 2rem' }}
    >
      <Typography>
        {t('Identify payments by adding the payment reference to one or more orders or settlements. To proceed, ensure that the destination currency matches the payment currency and that you assign the full amount')}
      </Typography>
      <AssignTotalAmountBox
        amount={amount}
        currency={currency}
      />
      <InputsGroup layout="column" marginBottom="1rem">
        <ReferenceInput
          source="merchantId"
          reference="entity-v2/reference"
          filter={{ entityRoles: entityRoles.MERCHANT }}
          alwaysOn
        >
          <AutocompleteInput
            label={t('Merchant')}
            optionText={renderReference}
            suggestionLimit={20}
            placeholder={t('Select merchant')}
            shouldUseNarrowLabel
            fullWidth
          />
        </ReferenceInput>
        <TooltipBox
          tooltip={t('Link this payment reference to a merchant to prevent conflicts caused by duplicated references')}
          sx={{ marginTop: '2.25rem', marginLeft: '-2rem' }}
        />
      </InputsGroup>
      <ArrayInput
        source="payments"
        label={false}
      >
        <SimpleFormIterator
          addButtonLabel={t('Add order or settlement')}
          itemLabel={t('Order or settlement')}
          customDefaultValue={{ amount: roundNumber(leftToAssign) }}
        >
          <InputsGroup layout="column">
            <TextInput
              label={t('Payment reference')}
              source="paymentReference"
              placeholder={t('E.g 8389295802')}
              validate={[required(t('Please enter a payment reference to the account the unidentified payment should be matched to'))]}
            />
            <NumberInput
              label={t('Amount')}
              source="amount"
              placeholder={t(`E.g ${amount < 0 ? '-' : ''}300.00`)}
              validate={[...validateRequired, nonZeroNumberValidation, twoDecimalsValidation]}
            />
          </InputsGroup>
        </SimpleFormIterator>
      </ArrayInput>
      { hasError && amount > 0 && (<WarningBox warningText={t('Error! Please remove the “-” symbol to match the original payment.')} />)}
      { hasError && amount < 0 && (<WarningBox warningText={t('Error! Please add a “-” symbol to the amount to match the original payment.')} />)}
    </ModalButton>
  );
};

const IdentifyButtonWithPaymentContext = () => (
  <AssignedPaymentContextProvider>
    <IdentifyButton />
  </AssignedPaymentContextProvider>
);

export default IdentifyButtonWithPaymentContext;
