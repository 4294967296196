import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  required,
  maxValue,
  useRecordContext,
  useResourceContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { useConstantContext } from '../ConstantsContext';

import TextInput from '../designSystem/react-admin/inputs/TextInput';
import CurrencyInput from '../designSystem/react-admin/inputs/CurrencyInput';
import PercentageInput from '../designSystem/react-admin/inputs/PercentageInput';
import ModalButton from '../designSystem/ModalButton';
import InputsGroup from '../designSystem/InputsGroup';
import SummaryGrid from '../designSystem/SummaryGrid';

import SimpleShowLabeled from './SimpleShowLabeled';
import CurrencyField from './CurrencyField';

import useCustomRpc from '../hooks/useCustomRpc';
import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';

import { getEntityIdFromToken, getEntityEnableCreditNotesToken } from '../../utils/getValueFromToken';
import { positiveNumberValidation, strictlyPositiveNumberValidation } from '../../utils/validationErrors';

const validateRequired = [required()];

const CreateCreditNote = ({ accountRecord }) => {
  const invoiceRecord = useRecordContext();
  const { t } = useTranslation();
  const { isOwner, isMerchant } = useUserPermissionsAndRoles();
  const { constants } = useConstantContext();
  const enableCreditNotes = getEntityEnableCreditNotesToken();
  const resource = useResourceContext();

  const entityId = getEntityIdFromToken();

  const { accountTypes } = constants;

  const {
    id,
    articles,
    orderAmount,
    balance,
    currency,
    creditorId,
    accountType,
    customVatPercentage,
  } = accountRecord;

  const {
    remainingBalance,
  } = invoiceRecord;

  const isCreditor = creditorId === entityId;

  const {
    mutate: addAdjustment,
    isLoading,
  } = useCustomRpc({
    path: `${resource}/${id}/add-adjustment`,
    httpMethod: 'POST',
    shouldRefresh: true,
    errorMessage: t('Could not create credit note'),
  });

  const onSubmit = useCallback((formData) => {
    const data = {
      amount: formData.creditAmount,
      description: formData.comment,
      vatPercentage: formData.vatPercentage,
    };
    addAdjustment(data);
    return true;
  }, [addAdjustment]);

  if ((!isOwner && !enableCreditNotes)
    || (!isCreditor && isMerchant)
    || balance <= 0
    || accountType === accountTypes.BILLING
    || (accountType === accountTypes.ORDER && !articles)
  ) return null;

  return (
    <ModalButton
      modalTitle={t('Create credit note')}
      openButtonLabel={t('Credit note')}
      actionLabel={t('Create credit note')}
      variant="subAction"
      onClick={onSubmit}
      disabled={isLoading}
      withForm
      width="47.5rem"
      formDefaultValue={{
        articles,
        currency,
        orderAmount,
        remainingBalance,
      }}
    >
      <Typography marginBottom="0.5rem" fontSize="15px" maxWidth="30rem">
        {t('The credit note reduces the customer\'s upcoming payment by deducting the specified amount from the total order amount. You can only issue a credit note for amounts that have not yet been paid')}
      </Typography>
      <SummaryGrid>
        <Grid item lg={4} md={6} flex="flex" whiteSpace="nowrap" textAlign="left">
          <SimpleShowLabeled>
            <CurrencyField
              label={t('Order amount')}
              source="orderAmount"
              currencySource="currency"
              fontWeight="600"
            />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6} mr="1rem" textAlign="left">
          <SimpleShowLabeled>
            <CurrencyField
              label={t('Unpaid amount')}
              source="remainingBalance"
              currencySource="currency"
              fontWeight="600"
            />
          </SimpleShowLabeled>
        </Grid>
      </SummaryGrid>
      <InputsGroup layout="column">
        <CurrencyInput
          label={t('Credit amount')}
          source="creditAmount"
          placeholder={t('E.g. 5000')}
          currency={currency}
          validate={[
            maxValue(remainingBalance),
            strictlyPositiveNumberValidation,
            ...validateRequired,
          ]}
        />
        <PercentageInput
          source="vatPercentage"
          label={`${t('VAT')} (%)`}
          placeholder={t('E.g. 10')}
          validate={[
            positiveNumberValidation,
            ...validateRequired,
          ]}
          defaultValue={customVatPercentage}
        />
      </InputsGroup>
      <TextInput
        sx={{ width: '100%' }}
        multiline
        source="comment"
        label={t('Comment')}
        placeholder={t('Add comment or reason for the credit note')}
        validate={validateRequired}
      />
    </ModalButton>
  );
};

CreateCreditNote.propTypes = {
  accountRecord: PropTypes.shape({
    id: PropTypes.string.isRequired,
    creditorId: PropTypes.string.isRequired,
    balance: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    articles: PropTypes.arrayOf(PropTypes.shape({})),
    orderAmount: PropTypes.number.isRequired,
    accountType: PropTypes.string.isRequired,
    customVatPercentage: PropTypes.number,
  }),
};

CreateCreditNote.defaultProps = {
  accountRecord: {},
};

export default CreateCreditNote;
