/**
 * NOTE: This file should be always align with api/src/utils/validators.js
 */
import parseMax from 'libphonenumber-js/max';
import { toUnicode } from 'punycode';
import dayjs from './dayjs';

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

export const isDefined = (value) => value !== undefined && value !== null && value !== '';
export const required = (validation, value, ...rest) => (isDefined(value)
  ? validation(value, ...rest)
  : false
);
export const optional = (validation, value, ...rest) => (isDefined(value)
  ? validation(value, ...rest)
  : true
);
export const isString = (value) => typeof value === 'string';
export const isValidEmail = (value) => isString(value)
  && EMAIL_REGEX.test(toUnicode(value));
export const isValidPhone = (value) => {
  if (!isString(value)) return false;
  const trimmedValue = value.replace(/\s/g, '');
  if (!trimmedValue.match(/^(\+\d{2})?\d{7,}$/)) return false;
  const parsed = parseMax(trimmedValue, { extract: false });
  return !!parsed && parsed.isValid();
};
export const isBoolean = (value) => typeof value === 'boolean';
export const isNumber = (value) => typeof value === 'number'
  && !Number.isNaN(value) && Number.isFinite(value);
export const isStrictlyPositiveNumber = (value) => isNumber(value)
  && value > 0;
export const isStrictlyNegativeNumber = (value) => isNumber(value)
  && value < 0;
export const isPositiveNumber = (value) => isNumber(value) && value >= 0;
export const isNegativeNumber = (value) => isNumber(value) && value <= 0;
export const isOneOfValues = (value, constants) => Object.values(constants).includes(value);
export const isValidArray = (value) => Array.isArray(value) && value.length > 0;
export const isObject = (value) => isDefined(value)
  && value.constructor === Object;
export const isEmptyObject = (value) => isObject(value)
  && Object.keys(value).length === 0
  && Object.entries(value).length === 0;
export const isObjectWithFields = (value) => isObject(value)
  && Object.keys(value).length > 0
  && Object.entries(value).length > 0;
export const allOfValues = (value, constants) => (
  isValidArray(value) && value.every((n) => Object.values(constants).includes(n))
);
export const isValidPassword = (value) => isString(value) && value.length > 3;
export const isArrayOf = (elementValidator, ...rest) => (value) => Array.isArray(value)
  && value.every((element) => elementValidator(element, ...rest));

export const luhnCheck = (value) => {
  const parity = value.length % 2;
  let checkDigit = 0;
  for (let index = value.length - 1; index >= 0; index -= 1) {
    let digit = parseInt(value[index], 10);
    if (index % 2 === parity) { digit *= 2; }
    if (digit > 9) { digit -= 9; }
    checkDigit += digit;
  }
  return checkDigit % 10 === 0;
};
export const digitCheck = (value) => luhnCheck(value);
export const isValidIBAN = (value) => isString(value) && /^([A-Z]{2})(\d{2})([A-Z\d]{1,27})$/g.test(value);
export const isValidBIC = (value) => isString(value) && /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/g.test(value);
export const isValidBankgiro = (value) => isString(value) && /^[0-9]{7,8}$/.test(value);
export const isValidVatNumber = (value) => isString(value) && /^[A-Z]{2}[A-Z0-9]{2,18}$/g.test(value);
export const isDate = (value) => dayjs(value).isValid();
export const isPercentage = (value) => isPositiveNumber(value)
  && value >= 0 && value <= 100;
export const fullAccountNumber = (value) => isDefined(value) && isString(value) && /^[0-9]{6,8}$/.test(value);
export const isPositivePercentage = (value) => isPositiveNumber(value) && value <= 100;
export const isStrictlyPositivePercentage = (value) => isStrictlyPositiveNumber(value) && value <= 100;
export const isOneElementArray = (value, validLength = 1) => isValidArray(value)
  && value.length === validLength;
export const isValidLeasingDuration = (value) => isStrictlyPositiveNumber(value)
  && (value % 12 === 0) && value <= 84;
export const isArray = (value) => Array.isArray(value);
export const isMaxCharacterLengthString = (
  value,
  maxLength = 50,
) => isString(value) && value.length <= maxLength;
