import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import renderReference from '../../../utils/renderReference';

import useUserPermissionsAndRoles from '../../hooks/useUserPermissionsAndRoles';
import { useConstantContext } from '../../ConstantsContext';
import SelectInput from '../../designSystem/react-admin/inputs/SelectInput';
import DateInput from '../../designSystem/react-admin/inputs/DateInput';
import ReferenceArrayInput from '../../designSystem/react-admin/inputs/ReferenceArrayInput';
import AutocompleteArrayInput from '../../designSystem/react-admin/inputs/AutocompleteArrayInput';
import useReportsFilterContext from './useReportsFilterContext';

const SentToPartnerStatus = (props) => {
  const { t } = useTranslation();
  return (
    <SelectInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      defaultValue=""
      choices={[
        { id: 'true', name: t('Moank AB') },
        { id: 'false', name: t('Lidion Bank PLC') },
      ]}
    />
  );
};
export const FinancingStatusInput = (props) => {
  const { handleHide } = useReportsFilterContext();
  const { t } = useTranslation();
  const handleHideFinancingStatus = useCallback((source) => () => {
    handleHide([source, 'filters.financeFrom', 'filters.financeTo'])();
  }, [handleHide]);

  return (
    <SelectInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      defaultValue="null"
      choices={[
        { id: 'true', name: t('Financed') },
        { id: 'false', name: t('Not Financed') },
        { id: 'null', name: t('All') },
      ]}
      handleHide={handleHideFinancingStatus}
    />
  );
};
export const PaymentStatusInput = (props) => {
  const { handleHide } = useReportsFilterContext();
  const { t } = useTranslation();
  const handleHidePaymentStatus = useCallback((source) => () => {
    handleHide([source, 'filters.paidFrom', 'filters.paidTo'])();
  }, [handleHide]);

  return (
    <SelectInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      defaultValue="null"
      choices={[
        { id: 'paid', name: t('Paid') },
        { id: 'unpaid', name: t('Unpaid') },
        { id: 'overdue', name: t('Overdue') },
        { id: 'notyetdue', name: t('Not yet due') },
        { id: 'null', name: t('All') },
      ]}
      handleHide={handleHidePaymentStatus}
    />
  );
};
const DecisionStatusInput = (props) => {
  const { t } = useTranslation();
  const { constants } = useConstantContext();
  const { decisionStatuses } = constants;

  return (
    <SelectInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      defaultValue=""
      choices={[
        { id: decisionStatuses.REFERRED, name: t('Referred') },
        { id: decisionStatuses.DENIED, name: t('Denied') },
      ]}
    />
  );
};

const InvoicingFilters = () => {
  const { constants, clientInfo } = useConstantContext();
  const { entityRoles } = constants;
  const { t } = useTranslation();

  const { isFinancer } = useUserPermissionsAndRoles();
  const { watch } = useFormContext();
  const paidSelect = watch('filters.orderStatus') === 'paid';
  const financedSelect = watch('filters.financed') === 'true';

  const [invoicingFilters, setInvoicingFilters] = useState([]);

  useEffect(() => {
    setInvoicingFilters([
      <DateInput
        source="date"
        label={t('Snapshot date')}
      />,
      isFinancer && (
        <ReferenceArrayInput
          source="filters.seller"
          label={t('Merchant')}
          reference="entity-v2/reference"
          resource="/entity-v2/reference"
          filter={{ entityRoles: entityRoles.MERCHANT }}
        >
          <AutocompleteArrayInput
            optionText={renderReference}
            suggestionLimit={20}
            label={t('Merchant')}
            options={{
              suggestionsContainerProps: {
                style: { zIndex: 1500 },
              },
            }}
          />
        </ReferenceArrayInput>
      ),
      <ReferenceArrayInput
        source="filters.debtor"
        label={t('Customer')}
        reference="entity-v2/reference"
        resource="/entity-v2/reference"
        filter={{ entityRoles: entityRoles.CUSTOMER }}
      >
        <AutocompleteArrayInput
          optionText={renderReference}
          label={t('Customer')}
          suggestionLimit={20}
          options={{
            suggestionsContainerProps: {
              style: { zIndex: 1500 },
            },
          }}
        />
      </ReferenceArrayInput>,
      <DateInput
        source="filters.issueDateFrom"
        label={t('Issue date (from)')}
      />,
      <DateInput
        source="filters.issueDateTo"
        label={t('Issue date (to)')}
      />,
      <DateInput
        source="filters.createdDateFrom"
        label={t('Creation date (from)')}
      />,
      <DateInput
        source="filters.createdDateTo"
        label={t('Creation date (to)')}
      />,
      <DateInput
        source="filters.dueFrom"
        label={t('Due date (from)')}
      />,
      <DateInput
        source="filters.dueTo"
        label={t('Due date (to)')}
      />,
      <FinancingStatusInput
        source="filters.financed"
        label={t('Financing status')}
      />,
      financedSelect && (
        <DateInput
          source="filters.financeFrom"
          label={t('Financing date (from)')}
        />
      ),
      financedSelect && (
        <DateInput
          source="filters.financeTo"
          label={t('Financing date (to)')}
        />
      ),
      <PaymentStatusInput
        source="filters.orderStatus"
        label={t('Payment status')}
      />,
      paidSelect && (
        <DateInput
          source="filters.paidFrom"
          label={t('Paid date (From)')}
        />
      ),
      paidSelect && (
        <DateInput
          source="filters.paidTo"
          label={t('Paid date (to)')}
        />
      ),
      clientInfo.defaultPartnerFinancer && (
        <SentToPartnerStatus
          source="filters.sentToPartner"
          label={t('Financer')}
        />
      ),
      isFinancer && (
        <ReferenceArrayInput
          label={t('Labels')}
          source="filters.labelIds"
          reference="label"
        >
          <AutocompleteArrayInput
            label={t('Labels')}
            variant="filled"
            optionText="name"
          />
        </ReferenceArrayInput>
      ),
      <DecisionStatusInput
        source="filters.decisionStatus"
        label={t('Decision status')}
      />,
      <DateInput
        source="filters.capitalisationDateFrom"
        label={t('Capitalisation from')}
      />,
      <DateInput
        source="filters.capitalisationDateTo"
        label={t('Capitalisation to')}
      />,
    ].filter((input) => !!input));
  }, [
    t,
    paidSelect,
    financedSelect,
    isFinancer,
    entityRoles,
    clientInfo.defaultPartnerFinancer,
  ]);

  return [invoicingFilters, setInvoicingFilters];
};

export default InvoicingFilters;
